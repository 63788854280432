import List from "components/common/List/list";
import { useRouter } from "next/router";
import { useState, useEffect } from "react";
import { TYPO } from "styles/Typo";
import InterestStar from "../components/InterestStar";
import { toDetailPage } from "utils/navigateToDetailPage";
import DividerButton from "components/common/divider/button";
import { CheckFill } from "../Detail/AI/Chart";
import useIsMobile from "states/deviceState";
import { KOREA } from "utils/constant/ITME_INFO";
import { useTranslation } from "next-i18next";

function StockList({
  stocklist,
  indexList,
  cat,
  name,
}: {
  stocklist: any;
  indexList?: any;
  cat: string;
  name: string;
}) {
  const router = useRouter();
  const { t } = useTranslation("main");
  const isMobile = useIsMobile();
  const [sliceCount, setSliceCount] = useState(
    router.pathname !== "/" ? 20 : 5
  );
  const handleWheel = (event: any) => {
    event.currentTarget.scrollLeft += event.deltaY;
  };

  return (
    <div className="w-full flex flex-col bg-white">
      <div className={`${TYPO.TYPO4_SB} text-gray80 pt-[30px] pl-6`}>
        {name}
      </div>
      {indexList && (
        <div
          className="overflow-x-scroll scroll"
          onWheel={isMobile ? undefined : handleWheel}
        >
          <div className="px-6 py-4 flex items-center w-fit ">
            {indexList.map((index: any, i: number) => (
              <div
                key={i}
                className={`flex items-center cursor-pointer ${
                  index.EW_SIGN_YN === 1 ? "gap-2" : ""
                }  shrink-0`}
                onClick={() => {
                  toDetailPage(router, index.ITEM_CD_DL);
                }}
              >
                <div
                  className={`flex flex-col  ${
                    i === 0
                      ? "pr-3"
                      : i < indexList.length - 1
                      ? "px-3"
                      : "pl-3"
                  }`}
                >
                  <span
                    className={`${TYPO.TYPO7_1_MD} text-gray50 whitespace-nowrap`}
                  >
                    {router.locale === "ko"
                      ? index.ITEM_KR_NM
                      : index.ITEM_ENG_NM}
                  </span>
                  <div className="flex items-center gap-1 ">
                    <span className={`${TYPO.TYPO7_SB} text-gray80`}>
                      {index?.LOC === KOREA
                        ? index.ADJ_CLOSE_KRW.toLocaleString("en-us", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : index.ADJ_CLOSE_USD.toLocaleString("en-us", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                    </span>
                    <span
                      className={`${TYPO.TYPO7_MD} ${
                        Number(index.ADJ_CLOSE_CHG.toFixed(2)) === 0
                          ? "text-gray60"
                          : Number(index.ADJ_CLOSE_CHG.toFixed(2)) > 0
                          ? "text-red40"
                          : "text-blue40"
                      }`}
                    >
                      {/**수익률 */}
                      {Number(index.ADJ_CLOSE_CHG.toFixed(2)) > 0 ? "+" : ""}
                      {index.ADJ_CLOSE_CHG.toFixed(2)}%
                    </span>
                  </div>
                </div>
                {index.EW_SIGN_YN === 1 && <CheckFill />}
                {i < indexList.length - 1 && (
                  <div className="w-[1px] bg-gray10 h-[48px] shrink-0"></div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="flex flex-col gap-2 py-2">
        <span
          className={`${TYPO.TYPO8_RG} text-gray70 w-full flex justify-end px-6`}
        >
          {cat === "crypto"
            ? router.locale === "ko"
              ? "시가총액 높은 순"
              : "Top Market Cap"
            : t("largeVolume")}
        </span>
        {[...stocklist.slice(0, sliceCount)].map(
          (asset: any, index: number) => (
            <div
              onClick={() => {
                toDetailPage(router, asset.ITEM_CD_DL);
              }}
              key={index}
              className="flex justify-between cursor-pointer"
            >
              <List
                num={index + 1}
                numColor={"text-blue40"}
                totalscore={asset?.TOTAL_PER}
                src={
                  `https://riskweather-public.s3.ap-northeast-2.amazonaws.com/logos/${asset.ITEM_CD_DL}.png` ||
                  "https://riskweather-public.s3.ap-northeast-2.amazonaws.com/logos/errorLogo.png"
                }
                item={
                  router.locale === "ko"
                    ? asset.ITEM_KR_NM && asset.ITEM_KR_NM
                    : asset.ITEM_ENG_NM && asset.ITEM_ENG_NM
                }
                price={
                  router.locale === "ko"
                    ? asset.ADJ_CLOSE_KRW &&
                      asset.ADJ_CLOSE_KRW.toLocaleString("en-us", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }) + (asset.CAT !== "Index" ? "원" : "")
                    : asset.ADJ_CLOSE &&
                      asset.ADJ_CLOSE.toLocaleString("en-us", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }) +
                        (asset.CAT !== "Index"
                          ? asset?.LOC === KOREA
                            ? "₩"
                            : "$"
                          : "")
                }
                percent={asset?.ADJ_CLOSE_CHG?.toLocaleString("en-us", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                })}
                HR_ITEM_NM={asset.HR_ITEM_NM}
                rightIcon={() => {
                  return (
                    <InterestStar
                      asset={asset}
                      size={30}
                      gtm={() => {
                        window.dataLayer.push({
                          event: "cMainListInterest",
                        });
                      }}
                    />
                  );
                }}
              />
            </div>
          )
        )}
      </div>
      <DividerButton
        text={t("viewMore")}
        className={
          router.pathname !== "/"
            ? sliceCount < stocklist.length
              ? ""
              : "hidden"
            : ""
        }
        onClick={() => {
          if (router.pathname === "/")
            router.push(
              `/ranking?loc=${
                cat == "korean" ? "korea" : cat == "us" ? "us" : "crypto"
              }`
            );
          else {
            // 데이터 추가
            if (sliceCount < stocklist.length)
              setSliceCount(
                sliceCount + 20 > stocklist.length
                  ? stocklist.length
                  : sliceCount + 20
              );
          }
        }}
      />
    </div>
  );
}

export default StockList;
